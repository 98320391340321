<template>
  <div>
    <!-- CONTENT -->
    <!--begin::Navbar-->
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-0 pb-0">
        <!--begin::Navs-->
        <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
          <!--begin::Nav item-->
          <li class="nav-item mt-2">
            <a
              class="nav-link text-active-primary ms-0 me-10 py-5"
              :class="{
                active: currentTab === 'details'
              }"
              href="#"
              @click.prevent="changeTab('details')"
            >
              Detalhes
            </a>
          </li>
          <!--end::Nav item-->
          <!--begin::Nav item-->
          <li class="nav-item mt-2">
            <a
              class="nav-link text-active-primary ms-0 me-10 py-5"
              :class="{
                active: currentTab === 'pixels'
              }"
              href="#"
              @click.prevent="changeTab('pixels')"
            >
              Pixels
            </a>
          </li>
          <!--end::Nav item-->
          <!--begin::Nav item-->
          <li class="nav-item mt-2">
            <a
              class="nav-link text-active-primary ms-0 me-10 py-5"
              :class="{
                active: currentTab === 'packages'
              }"
              href="#"
              @click.prevent="changeTab('packages')"
            >
              Grupos de Estratégias
            </a>
          </li>
          <!--end::Nav item-->
          <!--begin::Nav item-->
          <li class="nav-item mt-2">
            <a
              class="nav-link text-active-primary ms-0 me-10 py-5"
              :class="{
                active: currentTab === 'wallets'
              }"
              href="#"
              @click.prevent="changeTab('wallets')"
            >
              Histórico de transações
            </a>
          </li>
          <!--end::Nav item-->
        </ul>
        <!--begin::Navs-->
      </div>
    </div>
    <!--end::Navbar-->

    <!-- DETALHES TAB -->
    <!--begin::details View-->
    <div class="card mb-5 mb-xl-10" v-show="currentTab === 'details'">
      <!--begin::Card header-->
      <div class="card-header cursor-pointer">
        <!--begin::Card title-->
        <div class="card-title m-0">
          <h3 class="fw-bold m-0">Detalhes</h3>
        </div>
        <!--end::Card title-->
        <!--begin::Action-->
        <!--end::Action-->
      </div>
      <!--begin::Card header-->
      <!--begin::Card body-->
      <div class="card-body p-9">
        <template>
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">ID</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">{{ campaign.id }}</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">Nome</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">{{ campaign.name }}</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">Tipo</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">{{ campaign.type | formatCampaignType }}</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">Anunciante</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">
                <router-link
                  :to="{ name: 'advertisers.show', params: { id: campaign.advertiser.id }}"
                >
                  {{ campaign.advertiser.name }}
                </router-link>
              </span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">Data de início</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">{{ campaign.start_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">Data de término</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">{{ campaign.end_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">Valor</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">{{ campaign.amount/100 | formatMoney }}</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">Valor gasto</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">{{ campaign.spending/100 | formatMoney }}</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">Valor disponível</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">{{ campaign.available_amount/100 | formatMoney }}</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">Status</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">{{ campaign.status | formatStatus }}</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">Plataforma</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">{{ campaign.platform }}</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">ID na Plataforma</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">{{ campaign.platform_id }}</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">Impressões</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">{{ campaign.impressions }}</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">Cliques</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">{{ campaign.clicks }}</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">Conversões</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">{{ campaign.conversions }}</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">CTR</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">{{ campaign.ctr }}%</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">CPM</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">{{ campaign.cpm/100 | formatMoney }}</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">CPA</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">{{ campaign.cpa/100 | formatMoney }}</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">Data de criação</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">{{ campaign.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="row mb-7">
            <!--begin::Label-->
            <label class="col-lg-4 fw-semibold text-muted">Data de última atualização</label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <span class="fw-semibold text-gray-800 fs-6">{{ campaign.updated_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          <!-- IMPERSONATE -->
          <div class="row mb-7">
            <h2 class="m-0 text-gray-900 flex-grow-1">Impersonate</h2>
            <div class="mt-5">
              <ImpersonateButton
                :userId="campaign.user_impersonate_id"
              />
            </div>
          </div> <!-- END IMPERSONATE -->
        </template>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::details View--> <!-- END DETAILS TAB -->

    <!-- PIXELS TAB -->
    <!--begin::details View-->
    <div class="card mb-5 mb-xl-10" v-show="currentTab === 'pixels'">
      <!--begin::Card header-->
      <div class="card-header cursor-pointer">
        <!--begin::Card title-->
        <div class="card-title m-0">
          <h3 class="fw-bold m-0">Detalhes</h3>
        </div>
        <!--end::Card title-->
        <!--begin::Action-->
        <!--end::Action-->
      </div>
      <!--begin::Card header-->
      <!--begin::Card body-->
      <div class="card-body p-9">
        <template>
          <div class="table-responsive">
            <!--begin::Table-->
            <table class="table align-middle table-row-dashed fs-6 gy-5">
              <!--begin::Table head-->
              <thead>
                <!--begin::Table row-->
                <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                  <th class="min-w-100px">Nome do pixel</th>
                  <th class="min-w-70px">Nome da regra</th>
                  <th class="min-w-100px">Objetivo</th>
                  <th class="min-w-70px">Nome do evento</th>
                  <th class="min-w-70px">Status</th>
                </tr>
                <!--end::Table row-->
              </thead>
              <!--end::Table head-->
              <!--begin::Table body-->
              <tbody class="fw-semibold text-gray-600">
                <!--begin::Table row-->
                <tr v-for="(pixel, index) in campaign.pixels" :key="index">
                  <td>
                    <span>{{ pixel.pixel.name }}</span>
                  </td>
                  <td>
                    <span>{{ pixel.name }}</span>
                  </td>
                  <td>
                    <span>{{ pixel.objective }}</span>
                  </td>
                  <td>
                    <span>{{ pixel.event_name }}</span>
                  </td>
                  <td>
                    <span>{{ pixel.status | formatStatus }}</span>
                  </td>
                </tr>
                <!--end::Table row-->
              </tbody>
              <!--end::Table body-->
            </table>
          </div>
          <!--end::Table-->
        </template>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::details View--> <!-- END PIXELS TAB -->

    <!-- PACKAGES -->
    <template v-if="currentTab === 'packages' && campaign">
      <!--begin::Navbar-->
      <div class="card mb-5 mb-xl-10">
        <div class="card-body pt-0 pb-0">
          <!--begin::Navs-->
          <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
            <!--begin::Nav item-->
            <li class="nav-item mt-2" v-for="(pack, index) in campaign.packages" :key="index">
              <a
                class="nav-link text-active-primary ms-0 me-10 py-5"
                :class="{
                  active: currentPackageTab === index
                }"
                href="#"
                @click.prevent="changePackageTab(index)"
              >
                {{ pack.name }}
              </a>
            </li>
            <!--end::Nav item-->
          </ul>
          <!--begin::Navs-->
        </div>
      </div>
      <!--end::Navbar-->

      <!-- PACKAGES INFO -->
      <div>
        <div
          v-for="(pack, index) in campaign.packages" :key="index"
          v-show="currentPackageTab === index"
        >
          <!--begin::details View-->
          <div class="card mb-5 mb-xl-10">
            <!--begin::Card header-->
            <div class="card-header cursor-pointer">
              <!--begin::Card title-->
              <div class="card-title m-0">
                <h3 class="fw-bold m-0">Dados gerais</h3>
              </div>
              <!--end::Card title-->
              <!--begin::Action-->
              <!--end::Action-->
            </div>
            <!--begin::Card header-->
            <!--begin::Card body-->
            <div class="card-body p-9">
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">ID</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ pack.id }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Nome</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ pack.name }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Data de início</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ pack.start_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Data de término</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ pack.end_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Valor</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ pack.amount/100 | formatMoney }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Valor gasto</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ pack.spending/100 | formatMoney }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Valor disponível</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ (pack.amount - pack.spending)/100 | formatMoney }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Meta de CPM</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ pack.cpm_target_amount / 100 | formatMoney }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Meta de CPC</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ pack.cpc_target_amount / 100 | formatMoney }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Status</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ pack.status | formatStatus }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Plataforma</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ pack.platform }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">ID na Plataforma</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ pack.platform_id }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Impressões</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ pack.impressions }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Cliques</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ pack.clicks }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Conversões</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ pack.conversions }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">CTR</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ pack.ctr }}%</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">CPM</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ pack.cpm/100 | formatMoney }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">CPA</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ pack.cpa/100 | formatMoney }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Data de criação</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ pack.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Data de última atualização</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ pack.updated_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Card body-->
          </div>
          <!--end::details View-->
          <!-- LINES -->
          <div class="mt-4">
            <h2 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Estratégias</h2>
            <!-- LINES TABS -->
            <div class="mt-5">
              <!--begin::Navbar-->
              <div class="card mb-5 mb-xl-10">
                <div class="card-body pt-0 pb-0">
                  <!--begin::Navs-->
                  <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                    <!--begin::Nav item-->
                    <li class="nav-item mt-2" v-for="(line, lIndex) in pack.lines" :key="lIndex">
                      <a
                        class="nav-link text-active-primary ms-0 me-10 py-5"
                        :class="{
                          active: currentLineTab === lIndex
                        }"
                        href="#"
                        @click.prevent="changeLineTab(lIndex)"
                      >
                        <span :title="line.name">{{ line.name.length > 30 ? `${line.name.substring(0,30)}...` : line.name }}</span>
                      </a>
                    </li>
                    <!--end::Nav item-->
                  </ul>
                  <!--begin::Navs-->
                </div>
              </div>
              <!--end::Navbar-->
            </div> <!-- LINES TABS -->

            <!-- LINES CONTENT -->
            <div
              v-for="(line, index) in pack.lines" :key="index"
              v-show="currentLineTab === index"
            >
              <!--begin::details View-->
              <div class="card mb-5 mb-xl-10">
                <!--begin::Card header-->
                <div class="card-header cursor-pointer">
                  <!--begin::Card title-->
                  <div class="card-title m-0">
                    <h3 class="fw-bold m-0">Dados gerais</h3>
                  </div>
                  <!--end::Card title-->
                  <!--begin::Action-->
                  <!--end::Action-->
                </div>
                <!--begin::Card header-->
                <!--begin::Card body-->
                <div class="card-body p-9">
                  <!--begin::Input group-->
                  <div class="row mb-7">
                    <!--begin::Label-->
                    <label class="col-lg-4 fw-semibold text-muted">ID</label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                      <span class="fw-semibold text-gray-800 fs-6">{{ line.id }}</span>
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="row mb-7">
                    <!--begin::Label-->
                    <label class="col-lg-4 fw-semibold text-muted">Nome</label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                      <span class="fw-semibold text-gray-800 fs-6">{{ line.name }}</span>
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="row mb-7">
                    <!--begin::Label-->
                    <label class="col-lg-4 fw-semibold text-muted">Valor gasto</label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                      <span class="fw-semibold text-gray-800 fs-6">{{ line.spending/100 | formatMoney }}</span>
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="row mb-7">
                    <!--begin::Label-->
                    <label class="col-lg-4 fw-semibold text-muted">Status</label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                      <span class="fw-semibold text-gray-800 fs-6">{{ line.status | formatStatus }}</span>
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="row mb-7">
                    <!--begin::Label-->
                    <label class="col-lg-4 fw-semibold text-muted">Plataforma</label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                      <span class="fw-semibold text-gray-800 fs-6">{{ line.platform }}</span>
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="row mb-7">
                    <!--begin::Label-->
                    <label class="col-lg-4 fw-semibold text-muted">ID na Plataforma</label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                      <span class="fw-semibold text-gray-800 fs-6">{{ line.platform_id }}</span>
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="row mb-7">
                    <!--begin::Label-->
                    <label class="col-lg-4 fw-semibold text-muted">Impressões</label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                      <span class="fw-semibold text-gray-800 fs-6">{{ line.impressions }}</span>
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="row mb-7">
                    <!--begin::Label-->
                    <label class="col-lg-4 fw-semibold text-muted">Cliques</label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                      <span class="fw-semibold text-gray-800 fs-6">{{ pack.clicks }}</span>
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="row mb-7">
                    <!--begin::Label-->
                    <label class="col-lg-4 fw-semibold text-muted">Conversões</label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                      <span class="fw-semibold text-gray-800 fs-6">{{ line.conversions }}</span>
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="row mb-7">
                    <!--begin::Label-->
                    <label class="col-lg-4 fw-semibold text-muted">CTR</label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                      <span class="fw-semibold text-gray-800 fs-6">{{ line.ctr }}%</span>
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="row mb-7">
                    <!--begin::Label-->
                    <label class="col-lg-4 fw-semibold text-muted">CPM</label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                      <span class="fw-semibold text-gray-800 fs-6">{{ line.cpm/100 | formatMoney }}</span>
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="row mb-7">
                    <!--begin::Label-->
                    <label class="col-lg-4 fw-semibold text-muted">CPA</label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                      <span class="fw-semibold text-gray-800 fs-6">{{ line.cpa/100 | formatMoney }}</span>
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="row mb-7">
                    <!--begin::Label-->
                    <label class="col-lg-4 fw-semibold text-muted">Data de criação</label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                      <span class="fw-semibold text-gray-800 fs-6">{{ line.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="row mb-7">
                    <!--begin::Label-->
                    <label class="col-lg-4 fw-semibold text-muted">Data de última atualização</label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                      <span class="fw-semibold text-gray-800 fs-6">{{ line.updated_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Input group-->
                </div>
                <!--end::Card body-->
              </div>
              <!--end::details View-->

              <!--begin::details View-->
              <div class="card mb-5 mb-xl-10">
                <!--begin::Card header-->
                <div class="card-header cursor-pointer">
                  <!--begin::Card title-->
                  <div class="card-title m-0">
                    <h3 class="fw-bold m-0">Criativos</h3>
                  </div>
                  <!--end::Card title-->
                  <!--begin::Action-->
                  <!--end::Action-->
                </div>
                <!--begin::Card header-->
                <!--begin::Card body-->
                <div class="card-body p-9">
                  <CreativeListTable
                    :creatives="line.creatives"
                  />
                </div>
                <!--end::Card body-->
              </div>
              <!--end::details View-->

              <!--begin::details View-->
              <div class="card mb-5 mb-xl-10">
                <!--begin::Card header-->
                <div class="card-header cursor-pointer">
                  <!--begin::Card title-->
                  <div class="card-title m-0">
                    <h3 class="fw-bold m-0">Targeting</h3>
                  </div>
                  <!--end::Card title-->
                  <!--begin::Action-->
                  <!--end::Action-->
                </div>
                <!--begin::Card header-->
                <!--begin::Card body-->
                <div class="card-body p-9">
                  <div class="mb-5">
                    <h4 class="fw-bold m-0 mb-3">Audiências</h4>
                    <template v-if="line.audiences.length > 0">
                      <ul class="mb-0">
                        <li
                          v-for="(audience, i) in line.audiences"
                          :key="i"
                        >
                          {{ audience.name }}
                        </li>
                      </ul>
                    </template>
                    <template v-else>
                      <p>Sem audiências selecionadas</p>
                    </template>
                  </div>
                  <div class="mb-5">
                    <h4 class="fw-bold m-0 mb-5">Localizações</h4>
                    <h5 class="fw-bold m-0 mb-5">
                      <span>Estados</span>
                    </h5>
                    <template v-if="line.states.length > 0">
                      <ul class="mb-0">
                        <li
                          v-for="(state, i) in line.states"
                          :key="i"
                        >
                          {{ state.name }}
                        </li>
                      </ul>
                    </template>
                    <template v-else>
                      <p>Sem estados selecionados</p>
                    </template>
                    <h5 class="fw-bold my-5">
                      <span>Cidades</span>
                    </h5>
                    <template v-if="line.cities.length > 0">
                      <ul class="mb-0">
                        <li
                          v-for="(city, i) in line.cities"
                          :key="i"
                        >
                          {{ city.name }} ({{ city.state_short_name }})
                        </li>
                      </ul>
                    </template>
                    <template v-else>
                      <p>Sem cidades selecionadas</p>
                    </template>
                  </div>
                  <div class="mb-5">
                    <h4 class="fw-bold m-0 mb-5">Browsers</h4>
                    <template v-if="line.browser.length > 0">
                      <ul class="mb-0">
                        <li
                          v-for="(browser, i) in line.browser"
                          :key="i"
                        >
                          {{ browser }}
                        </li>
                      </ul>
                    </template>
                    <template v-else>
                      <p>Sem browsers selecionados</p>
                    </template>
                  </div>
                  <div class="mb-5">
                    <h4 class="fw-bold m-0 mb-5">Audiências customizadas</h4>
                    <template v-if="line.custom_audiences.length > 0">
                      <ul class="mb-0">
                        <li
                          v-for="(audience, i) in line.custom_audiences"
                          :key="i"
                        >
                          {{ audience.name }}
                        </li>
                      </ul>
                    </template>
                    <template v-else>
                      <p>Sem audiências customizadas selecionadas</p>
                    </template>
                  </div>
                  <div class="mb-5">
                    <h4 class="fw-bold m-0 mb-5">Blocklist</h4>
                    <template v-if="line.blacklist.length > 0">
                      <ul class="mb-0">
                        <li
                          v-for="(item, i) in line.blacklist"
                          :key="i"
                        >
                          {{ item.name }}
                        </li>
                      </ul>
                    </template>
                    <template v-else>
                      <p>Sem blocklist selecionada</p>
                    </template>
                  </div>
                  <div class="mb-5">
                    <h4 class="fw-bold m-0 mb-5">Wishlist</h4>
                    <template v-if="line.wishlist.length > 0">
                      <ul class="mb-0">
                        <li
                          v-for="(item, i) in line.wishlist"
                          :key="i"
                        >
                          {{ item.name }}
                        </li>
                      </ul>
                    </template>
                    <template v-else>
                      <p>Sem wishlist selecionada</p>
                    </template>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
              <!--end::details View-->

            </div><!-- END LINES CONTENT -->

          </div> <!-- END LINES -->
        </div>
      </div> <!-- END PACKAGES INFO -->
    </template> <!-- END PACKAGES -->

    <!-- WALLETS TAB -->
      <!--begin::details View-->
      <div class="card mb-5 mb-xl-10" v-show="currentTab === 'wallets'">
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bold m-0">Histórico de transações</h3>
          </div>
          <!--end::Card title-->
          <!--begin::Action-->
          <!--end::Action-->
        </div>
        <!--begin::Card header-->
        <!--begin::Card body-->
        <div class="card-body p-9" v-if="campaign">
          <WalletListTable
            :campaignId="campaign.id"
          />
        </div>
        <!--end::Card body-->
      </div>
      <!--end::details View--> <!-- END WALLETS TAB -->

    <!-- END CONTENT -->
  </div>
</template>

<script>

import CreativeListTable from '@/components/creatives/CreativeListTable'
import ImpersonateButton from '@/components/users/ImpersonateButton'
import WalletListTable from '@/components/wallets/WalletListTable'

export default {
  props: ['baseCampaign'],
  components: {
    CreativeListTable,
    ImpersonateButton,
    WalletListTable
  },
  data () {
    return {
      campaign: '',
      currentLineTab: 0,
      currentPackageTab: 0,
      currentTab: 'details',
      loader: false
    }
  },
  created () {
    this.campaign = this.baseCampaign
  },
  computed: {
    /**
     * Current tab title
     */
    tabTitle () {
      switch (this.currentTab) {
        case 'packages':
          return 'Grupos de Estratégias'
        default:
          return 'Detalhes'
      }
    }
  },
  methods: {
    /**
     * Change line tab
     */
    changeLineTab (tab) {
      this.currentLineTab = tab
    },
    /**
     * Change package tab and reset line tab
     */
    changePackageTab (tab) {
      this.currentPackageTab = tab
      this.currentLineTab = 0
    },
    /**
     * Change current tab
     */
    changeTab (tab) {
      this.currentTab = tab
    }
  }
}
</script>
